import axios from "axios";

const apipython = axios.create({

    baseURL: "https://se.simplestec.com:4338/",
    // baseURL: "http://127.0.0.1:4338/",

    headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
    },

});

export default apipython;
