import api from '../axios'
import apipython from '../axiospython'


export default class OrcamentoService {

    getssorctoindex() {
        return api.get('api/ssorctoindex' ).then((res) => res.data);
    }

    getssorctopdf(id) {
        // return api.get('api/ssorctopdf/' + id ).then((res) => res.data);

        return api({
            url: api.getUri() + 'api/ssorctopdf/' + id,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Orcamento Dynamis ' + id + '.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    }

    confirmarorcto(id) {
        const params = { id }
        return api.get('api/ssorctoconf', {params}).then((res) => res.data);
    }

    getssorcto(id) {
        const params = { id }
        return api.get('api/ssorctoget', {params}).then((res) => res.data);
    }

    savessorcto(orcto) {
        return api.post('api/ssorctosave', {orcto} ).then((res) => res.data);
    }

    savessetapa(etapa) {
        return api.post('api/ssetapasave', etapa ).then((res) => res.data);
    }

    getOrcamentos() {
        return fetch('demo/data/orcamentos.json').then(res => res.json()).then(d => d.data);

        // return api.get('api/orcamentos' ).then((res) => res.data);
    }

    getTesteApi(params){
        // console.log(baseURLPython)
        return api.get('api/testarapiauth', {params}).then((res) => res.data);
    }

    getListasFS(params){
        return apipython.get('obterlistas', {params}).then((res) => res.data);
    }

    savelistas(listas) {
        return api.post('api/sslistasave', {listas} ).then((res) => res.data);
    }

    getGeradoresFS(params){
        return apipython.get('cotarsistema', {params}).then((res) => res.data);
    }

    getAtualizarCota(params){
        return apipython.get('atualizarsistema', {params}).then((res) => res.data);
    }

    getIncremSist(params){
        return apipython.get('incremsistema', {params}).then((res) => res.data);
    }

    getEscolheGerador(params){
        return apipython.get('confirmarsistema', {params}).then((res) => res.data);
    }


    getAnalConta(files){
        console.log('getAnalConta')
        console.log(files)
        return apipython.post('analconta', files).then((res) => res.data);
    }

    savecotacao(pedido) {
        return api.post('api/sscotasave', {pedido} ).then((res) => res.data);
    }

    getPesqCliente(params){
        // console.log(baseURLPython)
        return api.get('api/sspesqcli', {params}).then((res) => res.data);
    }

    getCliente(id){
        const params = { id }
        return api.get('api/ssgetcli', {params}).then((res) => res.data);
    }

    getPDF(params){
        return api.get('api/ssorctopdf', {params}).then((res) => res.data);
    }

    getCountries() {
        return fetch('demo/data/countries.json').then(res => res.json())
            .then(d => d.data);
    }












    getLeads() {
        return fetch('demo/data/leads.json').then(res => res.json()).then(d => d.data);
    }



    getPedidos() {
        return fetch('demo/data/pedidos.json').then(res => res.json()).then(d => d.data);
    }

    getObras() {
        return fetch('demo/data/obras.json').then(res => res.json()).then(d => d.data);
    }

    getProjetos() {
        return fetch('demo/data/projetos.json').then(res => res.json()).then(d => d.data);
    }


    getProductsSmall() {
        return fetch('demo/data/products-small.json').then(res => res.json()).then(d => d.data);
    }

    getProductsMixed() {
        return fetch('demo/data/products-mixed.json').then(res => res.json()).then(d => d.data);
    }

    getProductsWithOrdersSmall() {
        return fetch('demo/data/products-orders-small.json').then(res => res.json()).then(d => d.data);
    }
}
